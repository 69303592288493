import React from "react";
import { InputText } from "primereact/inputtext";
import { CSVLink } from "react-csv";
import ReactDOM from "react-dom";
import { Dialog } from 'primereact/dialog';

let CheckinAPIUri = 'https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/checkin_service';

export async function remove_dancer_scratch (spinnerId, stateInfo) {
    showSpinner(spinnerId);

    await fetch(CheckinAPIUri, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'mark_scratched',
            'bib_number':  this.state.changeScratchBib,
            'undo': true
        })
    }).then(response => {
        return response.json();
    }).then(data => {       
        checkAndRemoveElementById("updateScratchDataContainer");
        const body = document.getElementById("updateScratchResult");
        const div = document.createElement("div");
        div.id = "updateScratchDataContainer";
        body.appendChild(div);

        if (data === 200) {
            ReactDOM.render(
                <div className="resultMessage" id="updateScratchData">
                    Scratch for dancer "{this.state.changeScratchBib}" has been removed!
                </div>, div
            );
            clearInputByClass('update-scratch-input');
            this.state.changeScratchBib = '';
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="updateScratchData">
                    Sorry, there was an error in the request!
                </div>, div
            );
        }

        hideSpinner(spinnerId);
    }).catch((error)=>{        
            hideSpinner(spinnerId);
    });
}

function showSpinner(id) {
    let spinner = document.getElementById(id);
    spinner.classList.remove("hide");
}

function hideSpinner(id) {
    let spinner = document.getElementById(id);
    spinner.classList.add("hide");
}

function checkAndRemoveElementById(id) {
    let previousDancerDiv = document.getElementById(id);
    if (previousDancerDiv != null) {
        previousDancerDiv.remove();
    }
}

function clearInputByClass(className){
    let inputs = document.getElementsByClassName(className);

    for(let input=0; input<inputs.length;input++){
        if (inputs[input] != null) {
            inputs[input].value='';
        }        
    }
}