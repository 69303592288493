import React from "react";
import { InputText } from "primereact/inputtext";
import { CSVLink } from "react-csv";
import ReactDOM from "react-dom";
import { Dialog } from 'primereact/dialog';
import Admin from '../Admin';

//let APIUri = 'https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/track_backend';
let AdminAPIUri = 'https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/user_admin_service';
let CalcAPIUri = 'https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/calc_placements_service';
let UserAdminApiUri = 'https://xwggndk5wl.execute-api.us-east-1.amazonaws.com/default/user_admin_service';

export async function get_final_plaments_all() {
    showSpinner("AdminSpinner");
    await fetch(AdminAPIUri, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'get_final_plaments_all',
        })
    }).then(response => {
        let data = response.json();
        return data;
    }).then(data => {
        this.setState({ downloadData: data }, () => {
            // click the CSVLink component to trigger the CSV download
            this.csvLink.current.link.click()
        });

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        this.setState({ calcFailure: true });
    });
}

export async function track_placement_holds_export() {
    showSpinner("AdminSpinner");
    await fetch(CalcAPIUri, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'shakey_track_placement_export',
        })
    }).then(response => {
        let data = response.json();
        return data;
    }).then(data => {
        this.setState({ shakeyDownloadData: data }, () => {
            // click the CSVLink component to trigger the CSV download
            this.shakeyLink.current.link.click()
        });

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        this.setState({ calcFailure: true });
    });
}

export async function calculate_final_placments() {
    showSpinner("AdminSpinner");
    const response = await fetch(CalcAPIUri, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'calculate_final_placments',
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        if (data.length !== 0) {
            this.setState({ calcSuccess: true });
        }
        else {
            //this.setState({ calcFailure: true });
        }
        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        //this.setState({ calcFailure: true });
    });
}

export async function update_bib_number() {
    showSpinner("AdminSpinner");
    await fetch(UserAdminApiUri, {
        // mode: 'no-cors',
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'update_bib_number',
            'bib_number': this.state.updateOldBib,
            'new_bib_number': this.state.updateNewBib
        })

    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("bib-update-data-container");
        const body = document.getElementById("updateBibResult");
        const div = document.createElement("div");
        div.id = "bib-update-data-container";
        body.appendChild(div);

        if (data === 200) {
            ReactDOM.render(
                <div id="bib-update-data">
                    Bib number updated from {this.state.updateOldBib} to {this.state.updateNewBib}!
                </div>, div
            );
            clearInputByClass('update-bib-input');
            this.state.updateOldBib = '';
            this.state.updateNewBib = '';
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="bib-update-data">
                    Sorry, bib not found!
                </div>, div
            );
        }
        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        this.setState({ calcFailure: true });
    });
}

export async function add_dancer() {
    showSpinner("AdminSpinner");
    let sortKey = this.state.addBibNum + "_" + this.state.addDancerRole + "_" + this.state.addHeat + "_default";//bib_role_heat_judge(default)

    await fetch(UserAdminApiUri, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'add_dancer',
            'partition_key': this.state.addHeat,
            'sort_key': sortKey,
            'bib_number': this.state.addBibNum,
            'dancer_name': this.state.addDancerName,
            'dancer_email': this.state.addDancerEmail,
            'dancer_role': this.state.addDancerRole,
            'heat': this.state.addHeat,
            'judge': 'default',
            'placement': this.state.addPlacement,
            'here':'no',
            'scratch':'no',
            'shakey' : 'no',
            'badged': 'no'
        })

    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("add-dancer-data-container");
        const body = document.getElementById("AddDancerResult");
        const div = document.createElement("div");
        div.id = "add-dancer-data-container";
        body.appendChild(div);

        if (data === 200) {
            ReactDOM.render(
                <div className="resultMessage" id="add-dancer-data">
                    Dancer "{this.state.addBibNum}-{this.state.addDancerName}" added successfully!
                </div>, div
            );
            clearInputByClass('add-dancer-input');
            this.state.addHeat = '';
            this.state.addBibNum = '';
            this.state.addDancerName = '';
            this.state.addDancerEmail = '';
            this.state.addDancerRole = '';
            this.state.addHeat = '';
            this.state.addPlacement = '';
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="add-dancer-data">
                    Sorry, something went wrong!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        this.setState({ calcFailure: true });
    });
}

export async function change_dancer_heat() {
    showSpinner("AdminSpinner");

    await fetch(UserAdminApiUri, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'change_dancer_heat',
            'bib_number': this.state.changeHeatBib,
            'new_heat': this.state.changeHeatNew
        })

    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("heat-update-data-container");
        const body = document.getElementById("updateHeatResult");
        const div = document.createElement("div");
        div.id = "heat-update-data-container";
        body.appendChild(div);

        if (data === 200) {
            ReactDOM.render(
                <div className="resultMessage" id="heat-update-data">
                    Heat for dancer "{this.state.changeHeatBib}" updated to heat {this.state.changeHeatNew}!
                </div>, div
            );
            clearInputByClass('update-heat-input');
            this.state.changeHeatBib = '';
            this.state.changeHeatNew = '';
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="heat-update-data">
                    Sorry, there was an error in the request!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
            hideSpinner("AdminSpinner");
            this.setState({ calcFailure: true });
    });
}

export async function change_dancer_name() {
    showSpinner("AdminSpinner");
    
    await fetch(UserAdminApiUri, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'update_dancer_name',
            'bib_number': this.state.changeDancerNameBib,
            'new_name': this.state.changeDancerName
        })

    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("updateNameDataContainer");
        const body = document.getElementById("updateNameResult");
        const div = document.createElement("div");
        div.id = "updateNameDataContainer";
        body.appendChild(div);

        if (data === 200) {
            ReactDOM.render(
                <div className="resultMessage" id="updateNameData">
                    Name for dancer "{this.state.changeDancerNameBib}" updated to {this.state.changeDancerName}!
                </div>, div
            );
            clearInputByClass('update-dancer-name-input');
            this.state.changeDancerNameBib = '';
            this.state.changeDancerName = '';
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="updateNameData">
                    Sorry, there was an error in the request!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
            hideSpinner("AdminSpinner");
    });
}

export async function change_dancer_role() {
    showSpinner("AdminSpinner");
    
    await fetch(UserAdminApiUri, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'update_dancer_role',
            'bib_number': this.state.changeDancerRoleBib,
            'new_role': this.state.changeDancerRole
        })

    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("updateRoleDataContainer");
        const body = document.getElementById("updateRoleResult");
        const div = document.createElement("div");
        div.id = "updateRoleDataContainer";
        body.appendChild(div);

        if (data === 200) {
            ReactDOM.render(
                <div className="resultMessage" id="updateRoleData">
                    Role for dancer "{this.state.changeDancerRoleBib}" updated to {this.state.changeDancerRole}!
                </div>, div
            );
            
            clearInputByClass('update-role-input');
            this.state.changeDancerRoleBib = '';
            this.state.changeDancerRole = '';
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="updateRoleData">
                    Sorry, there was an error in the request!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
            hideSpinner("AdminSpinner");
    });
}

export async function get_all_judge_placements_for_dancer() {
    showSpinner("AdminSpinner");
    
    await fetch(UserAdminApiUri, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'get_all_judge_placements_for_dancer',
            'bib_number': this.state.bibSearch
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("bib-search-data-container");
        const body = document.getElementById("dancerDetail");
        const div = document.createElement("div");
        div.id = "bib-search-data-container";
        body.appendChild(div);

        if (data.length !== 0) {
            ReactDOM.render(
                <div id="bib-search-data"> 
                    {Admin.renderDancerDetailTable(data)}
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="bib-search-data">
                    Sorry, dancer "{this.state.bibSearch}" not found!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        this.setState({ calcFailure: true });
    });
}

export async function get_all_judge_placements_for_dancer_by_name() {
    showSpinner("AdminSpinner");
    
    await fetch(UserAdminApiUri, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'get_all_judge_placements_for_dancer_by_name',
            'name': this.state.nameSearch
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("name-search-data-container");
        const body = document.getElementById("dancerNameDetail");
        const div = document.createElement("div");
        div.id = "name-search-data-container";
        body.appendChild(div);

        if (data.length !== 0) {
            ReactDOM.render(
                <div id="name-search-data">
                    {Admin.renderDancerNameDetailTable(data)}
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="name-search-data">
                    Sorry, dancer named "{this.state.nameSearch}" not found!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        this.setState({ calcFailure: true });
    });
}

export async function shakey_track_placement_export() {
    showSpinner("AdminSpinner");
    
    await fetch(CalcAPIUri, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'shakey_track_placement_export'
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("shaky-data-container");
        const body = document.getElementById("shakeyDetail");
        const div = document.createElement("div");
        div.id = "shaky-data-container";
        body.appendChild(div);

        if (data.length !== 0) {
            ReactDOM.render(
                <div id="shaky-data">
                    {Admin.renderShakeyDancePlacementsTable(data)}
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="shaky-data">
                    No potential holds!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        this.setState({ calcFailure: true });
    });
}

export async function track_balance_totals() {
    showSpinner("AdminSpinner");
    
    await fetch(CalcAPIUri, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'track_balance_totals'
        })
    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("track-balance-data-container");
        const body = document.getElementById("trackBalanceDetail");
        const div = document.createElement("div");
        div.id = "track-balance-data-container";
        body.appendChild(div);

        if (data.length !== 0) {
            ReactDOM.render(
                <div id="track-balance-data">
                    {Admin.renderFinalPlacementCountsTable(data)}
                </div>, div
            );
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="track-balance-data">
                    Sorry, no final placements yet!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        this.setState({ calcFailure: true });
    });
}

export async function update_shakey_placements() {
    showSpinner("AdminSpinner");
    
    await fetch(CalcAPIUri, {
        crossDomain: true,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Connection': 'keep-alive'
        },
        body: JSON.stringify({
            'method_name': 'update_shakey_placements',
            'bib_number': this.state.overwriteBib,
            'new_placement': this.state.overwritePlacement
        })

    }).then(response => {
        return response.json();
    }).then(data => {
        checkAndRemoveElementById("overwrite-placement-data-container");
        const body = document.getElementById("overwritePlacementResult");
        const div = document.createElement("div");
        div.id = "overwrite-placement-data-container";
        body.appendChild(div);

        if (data === 200) {
            ReactDOM.render(
                <div className="resultMessage" id="overwrite-placement-data">
                    Dancer {this.state.overwriteBib}'s final placement has been overwritten to {this.state.overwritePlacement}, tap "Get Potiential Holds" again to see the current list of Holds.
                </div>, div
            );
            clearInputByClass('update-final-input');
            this.state.overwriteBib = '';
            this.state.overwritePlacement = '';
        }
        else {
            ReactDOM.render(
                <div className="resultMessage" id="overwrite-placement-data">
                    Sorry, there was an issue in the request!
                </div>, div
            );
        }

        hideSpinner("AdminSpinner");
    }).catch((error)=>{        
        hideSpinner("AdminSpinner");
        this.setState({ calcFailure: true });
    });
}


function showSpinner(id) {
    let spinner = document.getElementById(id);
    spinner.classList.remove("hide");
}

function hideSpinner(id) {
    let spinner = document.getElementById(id);
    spinner.classList.add("hide");
}

function checkAndRemoveElementById(id) {
    let previousDiv = document.getElementById(id);
    if (previousDiv != null) {
        previousDiv.remove();
    }
}

function clearInputByClass(className){
    let inputs = document.getElementsByClassName(className);

    for(let input=0; input<inputs.length;input++){
        if (inputs[input] != null) {
            inputs[input].value='';
            
        }        
    }
}