/*
Scratched dancers-get scratched dancers.
clear for all forms in admin view <----DONE
email accounts are case sensative in the screen views and log ins. 
*/

import React from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from "primereact/inputtext";
import { CSVLink } from "react-csv";
import ReactDOM from "react-dom";
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';

import {get_final_plaments_all, track_placement_holds_export, calculate_final_placments, update_bib_number, add_dancer, change_dancer_heat, change_dancer_name, change_dancer_role, get_all_judge_placements_for_dancer, get_all_judge_placements_for_dancer_by_name, shakey_track_placement_export, track_balance_totals, update_shakey_placements} from './APIs/AdminService'
import {remove_dancer_scratch} from './APIs/CheckinService'

let currentuser = "default";
const useradminmap = new Map();

export default class Admin extends React.Component {
    csvLink = React.createRef();
    shakeyLink = React.createRef();
    constructor(props) {
        super(props);
        currentuser = this.props.user
        useradminmap.set("sherie_502@yahoo.com", "sherie_carranza");
        useradminmap.set("justin.mann0511@gmail.com", "justin_mann");
        useradminmap.set("alesandrabull@gmail.com", "alesandra_bull");
        useradminmap.set("casey.bayer.9@gmail.com", "casey.bayer");
        useradminmap.set("iadrumman@gmail.com", "noah_price");
        useradminmap.set("crystalembrace@gmail.com", "danielle_ingram");

        this.state = {
            bibSearch: '',
            nameSearch: '',
            updateOldBib: '',
            updateNewBib: '',
            downloadData: [{}],
            shakeyDownloadData: [{}],
            finalDancer: [{}],
            dancerDetailVisible: true,
            calcSuccess: false,
            calcFailure: false,
            addBibNum: '',
            addDancerName: '',
            addDancerEmail: '',
            addDancerRole: '',
            addHeat: '',
            addPlacement: '',
            changeHeatBib: '',
            changeHeatNew: '',
            changeScratchBib: '',
            changeDancerName: '',
            changeDancerRoleBib: '',
            changeDancerRole: '',
            changeDancerNameBib: '',
            overwriteBib: '',
            overwritePlacement: ''
        };
    }

    componentDidMount() {
    }

    render() {
        if (useradminmap.has(currentuser)) {
            return (
                <div className="adminContainer">
                    <h1 className="pageTitle">Admin</h1>
                    <div className="adminButtonContainer">
                        {/* calculate placements button */}
                        <button type="button" className="btn btn-primary adminButton" onClick={calculate_final_placments.bind(this)} >Calculate Final Placements</button>

                        {/* export csv */}
                        <button type="button" className="btn btn-primary adminButton" onClick={get_final_plaments_all.bind(this)}>Export Final Placements</button>

                        {/* this hidden link will be clicked after fetching data */}
                        <CSVLink data={this.state.downloadData} filename={"final-lindyfest-placements.csv"} className="hidden" target="_blank" ref={this.csvLink}></CSVLink>
                        {/* this hidden link will be clicked after fetching data */}

                        <CSVLink data={this.state.shakeyDownloadData} filename={"potiential-holds-dancers.csv"} className="hidden" target="_blank" ref={this.shakeyLink}></CSVLink>
                    </div>
                    <Accordion multiple>
                        <AccordionTab header="Update Dancer">
                            <h4><u>Update bib number</u></h4>
                            <p className="adminInfoText">Use this update option only before final placements are calculated for heats.</p>

                            <label className="adminInputLabel">Old bib number:</label>
                            <InputText className="update-bib-input p-inputtext-sm" value={this.state.updateOldBib} onChange={(e) => this.setState({ updateOldBib: e.target.value })} />
                            <label className="adminInputLabel">New bib number:</label>
                            <InputText className="update-bib-input p-inputtext-sm" value={this.state.updateNewBib} onChange={(e) => this.setState({ updateNewBib: e.target.value })} />
                            <button type="button" className="btn btn-primary adminButton" onClick={update_bib_number.bind(this)}>Update</button>
                            <div id="updateBibResult"></div>
                            
                            <hr />

                            <h4><u>Change dancer heat</u></h4>
                            <p className="adminInfoText">Use this update option only before final placements are calculated for heats.</p>

                            <label className="adminInputLabel">Bib number:</label>
                            <InputText className="update-heat-input p-inputtext-sm" value={this.state.changeHeatBib} onChange={(e) => this.setState({ changeHeatBib: e.target.value })} />
                            <label className="adminInputLabel">New heat number:</label>
                            <InputText className="update-heat-input p-inputtext-sm" value={this.state.changeHeatNew} onChange={(e) => this.setState({ changeHeatNew: e.target.value })} />
                            <button type="button" className="btn btn-primary adminButton" onClick={change_dancer_heat.bind(this)}>Update</button>
                            <div id="updateHeatResult"></div>

                            <hr />

                            <h4><u>Remove dancer scratch</u></h4>
                            <label className="adminInputLabel">Bib number:</label>
                            <InputText className="update-scratch-input p-inputtext-sm" value={this.state.changeScratchBib} onChange={(e) => this.setState({ changeScratchBib: e.target.value })} />
                            <button type="button" className="btn btn-primary adminButton" onClick={remove_dancer_scratch.bind(this, "AdminSpinner")}>Update</button>
                            <div id="updateScratchResult"></div>

                            <hr />
                            <h4><u>Update dancer name</u></h4>
                            <label className="adminInputLabel">Bib number:</label>
                            <InputText className="update-dancer-name-input p-inputtext-sm" value={this.state.changeDancerNameBib} onChange={(e) => this.setState({ changeDancerNameBib: e.target.value })} />
                            <label className="adminInputLabel">New dancer name:</label>
                            <InputText className="update-dancer-name-input p-inputtext-sm" value={this.state.changeDancerName} onChange={(e) => this.setState({ changeDancerName: e.target.value })} />
                            <button type="button" className="btn btn-primary adminButton" onClick={change_dancer_name.bind(this)}>Update</button>
                            <div id="updateNameResult"></div>

                            <hr />
                            <h4><u>Update dancer role</u></h4>
                            <label className="adminInputLabel">Bib number:</label>
                            <InputText className="update-role-input p-inputtext-sm" value={this.state.changeDancerRoleBib} onChange={(e) => this.setState({ changeDancerRoleBib: e.target.value })} />
                            <label className="adminInputLabel">New dancer role:</label>
                            <InputText className="update-role-input p-inputtext-sm" value={this.state.changeDancerRole} onChange={(e) => this.setState({ changeDancerRole: e.target.value })} />
                            <button type="button" className="btn btn-primary adminButton" onClick={change_dancer_role.bind(this)}>Update</button>
                            <div id="updateRoleResult"></div>
                        </AccordionTab>
                        <AccordionTab header="Add New Dancer">
                            <p className="adminInfoText">Use this update option to add a new dancer. If the dancer was already in the system and lost their bib, use "Update Bib Number" above.</p>

                            <div className="adminInputContainer">
                                <label className="adminInputLabel">Bib number:</label>
                                <InputText className="add-dancer-input p-inputtext-sm" value={this.state.addBibNum} onChange={(e) => this.setState({ addBibNum: e.target.value })} />
                            </div>
                            <div className="adminInputContainer">
                                <label className="adminInputLabel">Heat number:</label>
                                <InputText className="add-dancer-input p-inputtext-sm" value={this.state.addHeat} onChange={(e) => this.setState({ addHeat: e.target.value })} />
                            </div>
                            <div className="adminInputContainer">
                                <label className="adminInputLabel">Dancer name:</label>
                                <InputText className="add-dancer-input p-inputtext-sm" value={this.state.addDancerName} onChange={(e) => this.setState({ addDancerName: e.target.value })} />
                            </div>
                            <div className="adminInputContainer">
                                <label className="adminInputLabel">Dancer email:</label>
                                <InputText className="add-dancer-input p-inputtext-sm" value={this.state.addDancerEmail} onChange={(e) => this.setState({ addDancerEmail: e.target.value })} />
                            </div>
                            <div className="adminInputContainer">
                                <label className="adminInputLabel">Dancer Role:</label>
                                <InputText className="add-dancer-input p-inputtext-sm" value={this.state.addDancerRole} onChange={(e) => this.setState({ addDancerRole: e.target.value })} />
                            </div>
                            <div className="adminInputContainer">
                                <label className="adminInputLabel">Placement:</label>
                                <InputText className="add-dancer-input p-inputtext-sm" value={this.state.addPlacement} onChange={(e) => this.setState({ addPlacement: e.target.value })} />
                            </div>
                            <button type="button" className="btn btn-primary adminButton" onClick={add_dancer.bind(this)}>Add Dancer</button>

                            <div id="AddDancerResult"></div>

                        </AccordionTab>
                        <AccordionTab header="Search for Dancer">
                            <div>
                                <h5>Search by bib number</h5>
                                <label className="adminInputLabel">Bib number:</label>
                                <InputText className="p-inputtext-sm" value={this.state.bibSearch} onChange={(e) => this.setState({ bibSearch: e.target.value })} />
                                <button type="button" className="btn btn-primary adminButton" onClick={get_all_judge_placements_for_dancer.bind(this)}>Search</button>

                                <div id="dancerDetail">
                                </div>

                                <hr />

                                <h5>Search by dancer name</h5>
                                <label className="adminInputLabel">Dancer Name:</label>
                                <InputText className="p-inputtext-sm" value={this.state.nameSearch} onChange={(e) => this.setState({ nameSearch: e.target.value })} />
                                <button type="button" className="btn btn-primary adminButton" onClick={get_all_judge_placements_for_dancer_by_name.bind(this)}>Search</button>

                                <div id="dancerNameDetail">
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header="Potiential Holds Dancers">
                            <div>
                                <h5>Overwrite Final Placements</h5>
                                <label className="adminInputLabel">Bib Number:</label>
                                <InputText className="update-final-input p-inputtext-sm" value={this.state.overwriteBib} onChange={(e) => this.setState({ overwriteBib: e.target.value })} />
                                <label className="adminInputLabel">New Placement:</label>
                                <InputText className="update-final-input p-inputtext-sm" value={this.state.overwritePlacement} onChange={(e) => this.setState({ overwritePlacement: e.target.value })} />
                                <button type="button" className="btn btn-primary adminButton" onClick={update_shakey_placements.bind(this)}>Overwrite Final Placement</button>
                                <div id="overwritePlacementResult"></div>

                                <hr />

                                <h5>View Potiential Holds Dancers</h5>
                                <button type="button" className="btn btn-primary adminButton" onClick={shakey_track_placement_export.bind(this)}>Get Potiential Holds</button>

                                {/* export csv */}
                                <button type="button" className="btn btn-primary adminButton" onClick={track_placement_holds_export.bind(this)}>Export Potential Hold Placements</button>

                                <div id="shakeyDetail">
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header="View Final Placement Counts">
                            <div>
                                <p className="adminInfoText">Use this to get counts for all tracks, including potiential hold dancers.</p>
                                <p className="adminInfoText">Targets: 6 - 40 dancers, 20 couples</p>
                                <p className="adminInfoText">5 - 60 dancers, 30 couples</p>
                                <p className="adminInfoText">4 - 80 dancers, 40 couples</p>
                                <p className="adminInfoText">3 - 60 dancers, 30 couples</p>
                                <button type="button" className="btn btn-primary adminButton" onClick={track_balance_totals.bind(this)}>Get Final Counts</button>

                                <div id="trackBalanceDetail">
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>

                    {/************************** hidden dialog boxes **************************/}
                    <Dialog header="Success" visible={this.state.calcSuccess} style={{ width: '50vw' }} onHide={() => this.setState({ calcSuccess: false })}>
                        <p className="m-0">
                            Final placements have been calculated successfully!
                        </p>
                    </Dialog>
                    <Dialog header="Failure" visible={this.state.calcFailure} style={{ width: '50vw' }} onHide={() => this.setState({ calcFailure: false })}>
                        <p className="m-0">
                            There was an error in the response!
                        </p>
                    </Dialog>
                    <div id="AdminSpinner" className="spinner-parent hide">
                        <div className="spinner-container justify-content-center">
                            <ProgressSpinner />
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className='body-container'>Not an Admin you are! </div>
            );
        }
    }

    static renderDancerDetailTable(dancer) {
        return (
            <div className="searchResultsContainer">
                <DataTable value={dancer} stripedRows scrollable responsiveLayout="scroll" scrollHeight="flex">
                    <Column field="bib_number" header="Bib" ></Column>
                    <Column field="dancer_name" header="Name" ></Column>
                    <Column field="judge" header="Placement Type" sortable></Column>
                    <Column field="dancer_role" header="Role"></Column>
                    <Column field="placement" header="Placement" sortable></Column>
                    <Column field="heat" header="Heat" sortable></Column>
                    <Column field="shakey" header="Hold?" sortable></Column>
                    <Column field="here" header="Here?" sortable></Column>
                    <Column field="scratch" header="Scratched?" sortable></Column>
                </DataTable>
            </div>
        );
    }
    static renderDancerNameDetailTable(dancer) {
        return (
            <div className="searchResultsContainer">
                <DataTable value={dancer} stripedRows scrollable responsiveLayout="scroll" scrollHeight="flex">
                    <Column field="bib_number" header="Bib" sortable></Column>
                    <Column field="dancer_name" header="Name" sortable></Column>
                    <Column field="judge" header="Placement Type" sortable></Column>
                    <Column field="dancer_role" header="Role"></Column>
                    <Column field="placement" header="Placement" sortable></Column>
                    <Column field="heat" header="Heat" sortable></Column>
                    <Column field="shakey" header="Hold?" sortable></Column>
                    <Column field="here" header="Here?" sortable></Column>
                    <Column field="scratch" header="Scratched?" sortable></Column>
                </DataTable>
            </div>
        );
    }

    static renderShakeyDancePlacementsTable(dancer) {
        return (
            <div className="searchResultsContainer">
                <DataTable value={dancer} stripedRows scrollable responsiveLayout="scroll" scrollHeight="flex">
                    <Column field="bib_number" header="Bib" sortable></Column>
                    <Column field="dancer_name" header="Name" sortable></Column>
                    <Column field="dancer_role" header="Role"></Column>
                    <Column field="placement" header="Placement" sortable></Column>
                    <Column field="all_placements" header="All Placement"></Column>
                    <Column field="heat" header="Heat" sortable></Column>
                    <Column field="shakey" header="Hold?" sortable></Column>
                    <Column field="here" header="Here?" sortable></Column>
                    <Column field="scratch" header="Scratched?" sortable></Column>
                </DataTable>
            </div>
        );
    }

    static renderFinalPlacementCountsTable(tracks) {
        return (
            <div className="searchResultsContainer">
                <DataTable value={tracks} stripedRows scrollable responsiveLayout="scroll" scrollHeight="flex">
                    <Column field="Track" header="Track" sortable></Column>
                    <Column field="Leaders" header="Leaders"></Column>
                    <Column field="Followers" header="Followers"></Column>
                </DataTable>
            </div>
        );
    }
}